export const AUTH_REDIRECT_DIRECTORY = {
	checkout: '/checkout',
	default: '/home',
	/**
	 * @deprecated No longer implemented.
	 */
	error: '/auth?error=could_not_authenticate',
	/**
	 * @deprecated Use `sbRedirect` instead.
	 * This will only be needed if we transition back to
	 * PKCE flow for email + password authentication.
	 */
	sbEmailRedirect: '/auth/confirm',
	/**
	 * @deprecated No longer implemented.
	 */
	sbRedirect: '/auth/callback',
	/**
	 * @deprecated No longer implemented. Tutorial is triggered using localStorage instead.
	 */
	tutorial: '/home?t=true'
} as const;
