import type { ClerkOptions } from '@clerk/types';

import * as Clerk from '@clerk/clerk-js';
import { logger } from '@tickrr/lib/logger';
import { type Writable, writable } from 'svelte/store';

import { clerkUiConfig } from './clerkUIConfig';

export type ClerkStore = Writable<Clerk.Clerk | null>;

export const clerk: ClerkStore = writable(null);

/**
 * Initializes the Clerk client with the provided Clerk store and publishable key.
 * @param publishableKey - The publishable key for Clerk.
 * @param optOverrides - Optional overrides for the Clerk client options.
 * @returns A promise that resolves when the Clerk client is initialized.
 * @throws Error if the publishable key is missing.
 */
export async function initClerkClient(
	publishableKey: string,
	optOverrides?: ClerkOptions
): Promise<void> {
	if (!publishableKey) {
		throw new Error('Missing PUBLIC_CLERK_PUBLISHABLE_KEY');
	}

	const client = new Clerk.Clerk(publishableKey);

	await client.load({ ...clerkUiConfig, ...optOverrides }).catch((error: Error) => {
		logger.error('[Clerk SvelteKit] Failed to load Clerk:', error);
	});

	client.addListener((event) => {
		if (event.user) {
			document.dispatchEvent(new CustomEvent('clerk-sveltekit:user', { detail: event.user }));
		}
	});

	clerk.set(client);

	clerk.subscribe((clerkInstance) => {
		if (clerkInstance) window.Clerk = clerkInstance;
	});
}
