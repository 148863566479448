import { AUTH_REDIRECT_DIRECTORY } from '@tickrr/lib/constants';

export const BASE_AUTH_CALLBACK_PATH = '/auth-callback';

/**
 * Constructs the authentication redirect URL.
 * This is helpful because we want to generally specify a `?shouldInvalidate=true` query parameter
 * so we can take additional steps to ensure the user's profile is loaded after redirect.
 *
 * @param path - The path to redirect to after authentication. Defaults to `AUTH_REDIRECT_DIRECTORY.default`.
 * @returns The constructed authentication redirect URL.
 */
export const constructAuthRedirect = (path: string = AUTH_REDIRECT_DIRECTORY.default) => {
	// return `${BASE_AUTH_CALLBACK_PATH}?redirectTo=${encodeURIComponent(path)}?shouldInvalidate=true`;
	return `${BASE_AUTH_CALLBACK_PATH}?redirectTo=${encodeURIComponent(path)}`;
};
