/* eslint-disable perfectionist/sort-objects */
import type { ClerkOptions } from '@clerk/types';

import { dark as clerkDarkTheme } from '@clerk/themes';

import { constructAuthRedirect } from './constructAuthRedirect.ts';

/**
 * Clerk client options.
 * Here, we can customize nearly ANYTHING related to the design
 * of Clerk components on our website.
 */
export const clerkUiConfig: ClerkOptions = {
	afterSignInUrl: constructAuthRedirect('/home'),
	afterSignUpUrl: constructAuthRedirect('/home'),
	appearance: {
		baseTheme: clerkDarkTheme,
		elements: {
			/**
			 * USER BUTTON AVATAR
			 */
			avatarBox: {
				border: '2px solid rgb(var(--color-surface-500))',
				borderRadius: '8px',
				height: '100%',
				width: '100%'
			},
			/**
			 * SIGN IN/SIGN UP COMPONENTS
			 */
			card: {
				background: 'rgb(var(--color-surface-800))',
				padding: '2rem 2rem'
			},
			cardBox: {
				border: '1px solid rgb(var(--color-surface-500))',
				boxShadow: `
				--tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
				--tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
				box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
				--tw-shadow-color: rgb(var(--color-surface-600) / 1);
				--tw-shadow: var(--tw-shadow-colored);
				`
			},
			footer: {
				background: 'rgb(var(--color-surface-600))'
			},

			formFieldLabel: {
				color: 'rgb(var(--color-surface-50))'
			},
			alternativeMethodsBlockButton: {
				paddingTop: '1rem',
				paddingBottom: '1rem'
			},
			formFieldInput: {
				borderRadius: '8px !important',
				background: 'rgb(var(--color-surface-50)) !important',
				color: 'rgb(var(--color-surface-900)) !important'
			},
			otpCodeFieldInput: {
				borderRadius: '6px !important',
				color: 'rgb(var(--color-surface-900)) !important',
				paddingLeft: '0px !important',
				paddingRight: '0px !important'
			},
			formButtonPrimary: {
				padding: '1rem'
			},
			socialButtonsBlockButton: {
				padding: '1rem',
				background: 'rgb(var(--color-surface-600))',
				color: 'rgb(var(--color-surface-50))',
				fontWeight: '600'
				// '&:hover': {
				// 	background: 'rgb(var(--color-surface-100))'
				// }
			},
			/**
			 * PROFILE PAGE
			 */
			navbar: {
				background: 'rgb(var(--color-surface-800))'
			},
			pageScrollBox: {
				background: 'rgb(var(--color-surface-800))'
			},
			/**
			 * USER BUTTON
			 */
			userButtonPopoverCard: {
				border: '1px solid rgb(var(--color-surface-300) / 0.25)'
			},
			userButtonPopoverFooter: {
				background: 'rgb(var(--color-surface-600)) !important'
			},
			userButtonPopoverMain: {
				background: 'rgb(var(--color-surface-800)) !important'
			}
		},
		/**
		 * @note
		 * All variables _MUST_ be hardcoded (they cannot hook into our Skeleton variables like above).
		 *
		 * Otherwise, if we do try to use a value like `rgb(var(--color-surface-50))`,
		 *  the components will _NOT MOUNT AT ALL_.
		 */
		variables: {
			borderRadius: '8px',
			colorBackground: '#151b23',
			colorDanger: '#ff3d64', // error-500
			colorNeutral: '#ffffff', // surface-100
			// colorPrimary: '#4beca4', // primary-500
			colorSuccess: '#4beca4', // primary-500
			// colorTextOnPrimaryBackground: '#151b23', // surface-500
			colorWarning: '#fff952' // warning-500
			// spacingUnit: '12px'
		}
	},
	signInUrl: '/signin',
	signUpUrl: '/signup',
	supportEmail: 'support@tickrr.io'
};
